import React from "react";

import { useTranslation } from "react-i18next";

function DiscountRulesTourEN(props: any) {
    const { t } = useTranslation();

    return (
        <>
            <p className="my-2">
                In all cases, <b>you must provide supporting evidence.</b>
            </p>
            {
                props.productCode === process.env.REACT_APP_BILHETE_BONDINHO
                    ?
                    <div>
                        <h5>TICKET BONDINHO PROMO BRASIL</h5>
                        <p>Discounts are granted to Brazilians born or residents, upon individual presentation of the following original documents (or certified copy), digital or printed.</p>
                        <ul>
                            <li>
                                <span>Adult Residents:</span>
                                <p>
                                    Proof of residence in your name (water, gas, electricity, cell phone, internet, card or telephone) and official photo ID (digital or printed).
                                </p>
                            </li>
                            <li>
                                <span>Adults Born:</span>
                                <p>
                                    Official identification document with photo, (digital or printed).
                                </p>
                            </li>
                        </ul>
                    </div>
                    :
                    ""
            }
            <div>
                <h5>FREE ADMISSION</h5>
                <ul>
                    <li>
                        <span>CLIMBERS</span>
                        <p>
                            Free descent for FEMERJ members, upon presentation of the membership card.
                        </p>
                    </li>
                    <li>
                        <span>CHILDREN UP TO 2 YEARS OLD</span>
                        <p>
                            They are exempt from payment upon presentation of original birth
                            certificate (or copy) or official identity card with photo (or
                            certified copy).
                        </p>
                    </li>
                </ul>
            </div>
            <div>
                <h5>HALF ENTRANCE</h5>
                <ul>
                    <li>
                        <span>CHILDREN FROM 3 TO 12 YEARS OLD</span>
                        <p>
                            Presentation of original birth certificate (or copy) or official
                            identity card with photo (or certified copy).
                        </p>
                    </li>
                    <li>
                        <span>YOUNG PEOPLE FROM 13 TO 21 YEARS OLD</span>
                        <p>Official identity card, with photo (or certified copy).</p>
                    </li>
                    <li>
                        <span>
                            YOUNG ID - YOUNGSTERS AGED BETWEEN 15 AND 29 YEARS OLD ENROLLED IN
                            THE SINGLE REGISTER FOR SOCIAL PROGRAMS OF THE FEDERAL GOVERNMENT
                        </span>
                        <p>
                            Presentation of the Young ID, and official identity card, with
                            photo (or certified copy).
                        </p>
                    </li>
                    <li>
                        <span>STUDENTS</span>
                        <p>
                            Elementary, High School, Graduate, Postgraduate, MBA, Masters and
                            Doctorates): original or certified copy of the National Student
                            Identification Card (CNIE), within the validity period or:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Public Schools: Presentation of the official identity card,
                                    with photo (or certified copy), accompanied by: RioCard-School
                                    or document, proving his/hers enrollment as a student, within
                                    the period of validity.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Particular Schools: Presentation of the official identity
                                    card, with photo (or certified copy), accompanied by: Payment
                                    slip to the institution of the current printed month or
                                    document proving the student’s enrollment, within the validity
                                    period.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Foreign Students: Present a student identification card with a
                                    photo.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>+ 60 YEARS OLD</span>
                        <p>
                            From 60 years old with an official identity card, with photo (or
                            certified copy).
                        </p>
                    </li>
                    <li>
                        <span>PWD AND COMPANION</span>
                        <p>
                            Presentation of the official identity card, with photo (or
                            certified copy), accompanied by a Special Card, RioCard, Vale
                            Social, Federal Pass, CRAS or medical report (original or
                            certified copy) issued by a public health agency. The discount is
                            limited to 1 companion per PWD.
                        </p>
                    </li>
                    <li>
                        <span>
                            TEACHERS AND TEACHING PROFESSIONALS - MUNICIPAL PUBLIC NETWORK OF
                            RIO DE JANEIRO, ACCORDING TO MUNICIPAL LAW No. 3.424/02, OF RIO DE
                            JANEIRO
                        </span>
                        <p>
                            Subimission of the official identity card, with photo (or
                            certified copy) and Functional Card issued by the Municipal
                            Department of Education or paycheck indentifying the employer or
                            school and position he/she holds.
                        </p>
                    </li>
                    <li>
                        <span>
                            GARIS OF THE MUNICIPAL CLEANING COMPANY (COMLURB), OF THE MUNICIPALITY OF RIO DE JANEIRO, ACCORDING TO MUNICIPAL LAW Nº 8.251/2024, OF RIO DE JANEIRO.
                        </span>
                        <p>
                            Presentation of official identification document with photo (or certified copy) and Functional Card or Badge issued by the official body.
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default DiscountRulesTourEN;
