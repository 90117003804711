import React from "react";

import { useTranslation } from "react-i18next";

function DiscountRulesTour(props: any) {
    const { t } = useTranslation();

    return (
        <>
            <p className="my-2">
                Em todos os casos, <b> é necessário apresentar documentação comprovativa.</b>
            </p>
            {
                props.productCode === process.env.REACT_APP_BILHETE_BONDINHO
                    ?
                    <div>
                        <h5>BILHETE BONDINHO PROMO BRASIL</h5>
                        <p>Os descontos são concedidos para nascidos ou moradores brasileiros, mediante apresentação individual dos seguintes documentos originais (ou cópia autenticada), digital ou impresso.</p>
                        <ul>
                            <li>
                                <span>Adultos Moradores:</span>
                                <p>
                                    Comprovante de residência em seu nome (água, gás, luz, celular, internet, cartão ou telefone) e documento de identificação oficial com foto (digital ou impresso).
                                </p>
                            </li>
                            <li>
                                <span>Adultos Nascidos:</span>
                                <p>
                                    Documento de identificação oficial com foto, (digital ou impresso).
                                </p>
                            </li>
                        </ul>
                    </div>
                    :
                    ""
            }
            <div>
                <h5>GRATUIDADE</h5>
                <ul>
                    <li>
                        <span>ESCALADORES</span>
                        <p>
                            Descida gratuita para filiados à FEMERJ, mediante a apresentação da credencial.
                        </p>
                    </li>
                    <li>
                        <span>CRIANÇAS ATÉ 2 ANOS</span>
                        <p>
                            Estão isentas de pagamento mediante a apresentação de certidão de
                            nascimento original (ou cópia) ou documento oficial de
                            identificação com foto (ou cópia autenticada).
                        </p>
                    </li>
                </ul>
            </div>
            <div>
                <h5>MEIA ENTRADA</h5>
                <ul>
                    <li>
                        <span>CRIANÇAS DE 3 A 12 ANOS</span>
                        <p>
                            Apresentação de certidão de nascimento original (ou cópia) ou
                            documento oficial de identificação com foto (ou cópia
                            autenticada).
                        </p>
                    </li>
                    <li>
                        <span>JOVENS DE 13 A 21 ANOS</span>
                        <p>
                            Documento oficial de identificação com foto (ou cópia
                            autenticada).
                        </p>
                    </li>
                    <li>
                        <span>
                            ID JOVEM - JOVENS COM IDADE ENTRE 15 E 29 ANOS INSCRITOS NO
                            CADASTRO ÚNICO PARA PROGRAMAS SOCIAIS DO GOVERNO FEDERAL
                        </span>
                        <p>
                            Apresentação da ID Jovem, acompanhada de documento oficial de
                            identificação com foto (ou cópia autenticada).
                        </p>
                    </li>
                    <li>
                        <span>ESTUDANTES</span>
                        <p>
                            Ensino Fundamental, Ensino Médio, Graduação, Pós-graduação, MBA,
                            Mestrados e Doutorados: original ou cópia autenticada da Carteira
                            Nacional de Identificação Estudantil (CNIE) dentro do prazo de
                            validade ou:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Rede Pública: apresentação do documento oficial de
                                    identificação com foto (ou cópia autenticada) acompanhado de:
                                    RioCard escolar ou documento que comprove a matrícula de
                                    estudante dentro do prazo de validade.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Rede Particular: apresentação do documento oficial de
                                    identificação com foto (ou cópia autenticada) acompanhado de:
                                    boleto de pagamento à instituição do mês atual ou documento
                                    que comprove a matrícula de estudante dentro do prazo de
                                    validade.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Estrangeiros: apresentar carteira de identificação estudantil
                                    com foto.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>+ 60 ANOS</span>
                        <p>
                            A partir de 60 anos com documento oficial de identificação com
                            foto (ou cópia autenticada).
                        </p>
                    </li>
                    <li>
                        <span>PCD E ACOMPANHANTE</span>
                        <p>
                            Apresentação do documento oficial de identificação com foto (ou
                            cópia autenticada) acompanhado de Cartão Especial, RioCard, Vale
                            Social, Passe Federal, CRAS ou laudo médico (original ou cópia
                            autenticada) emitido por órgão público de saúde. O desconto se
                            limita a 1 acompanhante por PCD.
                        </p>
                    </li>
                    <li>
                        <span>
                            PROFESSORES E PROFISSIONAIS DE ENSINO - REDE PÚBLICA MUNICIPAL DO
                            RIO DE JANEIRO, CONFORME LEI MUNICIPAL Nº 3.424/02, DO RIO DE
                            JANEIRO
                        </span>
                        <p>
                            Apresentação do documento de identidade oficial com foto (ou cópia
                            autenticada) e Carteira Funcional emitida pela Secretaria
                            Municipal de Educação ou contracheque que identifique o órgão ou
                            estabelecimento de ensino empregador e o cargo que ocupa.
                        </p>
                    </li>
                    <li>
                        <span>
                            GARIS DA COMPANHIA MUNICIPAL DE LIMPEZA (COMLURB), DO MUNICÍPIO DO RIO DE JANEIRO, CONFORME LEI MUNICIPAL Nº 8.251/2024, DO RIO DE JANEIRO.
                        </span>
                        <p>
                            Apresentação do documento oficial de identificação com foto (ou cópia autenticada) e Carteira Funcional ou Crachá emitido pelo órgão oficial.
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default DiscountRulesTour;
