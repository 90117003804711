import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import api from '../../../../services/api';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';

import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import '../PartialEdit/PartialEdit.scss';
import DoubleCalendar from '../../../Calendar/DoubleCalendar';
import DoubleCalendarTicket from '../../../Calendar/DoubleCalendarTicket';
import { set } from 'react-hook-form';

export interface propPartialEdit {
    setPartialReschedulingModal: any;
    currentReservation: any;
    setModalShow: any;
    setModalLog: any;
    setModalMessage: any;
}

const PartialRescheduling: React.FC<propPartialEdit> = ({
    setPartialReschedulingModal,
    currentReservation,
    setModalShow,
    setModalLog,
    setModalMessage,
}: propPartialEdit) => {
    const [item, setItem] = useState<any>(null);
    const [acessCode, setAcessCode] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selected, setSelected] = useState<number[]>([]);
    const [itemToBeListed, setItemToBeListed] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const local = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    const useId = local?.user?.id;
    const dateHours = item?.startTimeLocal?.split("T")[1];
    const [date, setDate] = useState<any>(null);
    const [option, setOption] = useState<any>(null);
    const [isIntegration, setIsIntegration] = useState<any>(false);

    useEffect(() => {
        const getProductInfo = async (obj: any) => {
            setItem(null);

            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
            };

            let apiName: string = obj.productType === 4 ? `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTicketAsync` : `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTourAsync`;

            try {
                const res = await api.post(apiName,
                    {
                        productCode: `${obj.productCode}`,
                        lang: "BR",
                        Slug: '',
                        idCanal: 2
                    },
                    config);
                if (res.status !== 400) {
                    setItem(obj)
                    setOption(res.data.data[obj.productType === 4 ? "modalities" : 'modalidade'].find((elem: any) => { return (elem.prodModCode === obj.idModalidade) }))
                }
            } catch (error: any) { console.log(error.response) }
        };

        const getDetails = async () => {
            setItem(null);

            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
            };
            try {
                const res = await api.get(`/api/NewReservations/GetNewReservationsByIdAsync/${currentReservation?.idNewItems}`, config);
                if (res.status !== 400) {
                    getProductInfo(res.data.data);
                }
            } catch (error: any) { console.log(error.response) }
        };

        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(item)
    }, [item])

    useEffect(() => {
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        async function listVoucher() {
            try {
                const { data } = await api.post(
                    `/api/Reservations/ListAllItemEdit`,
                    {
                        idItem: currentReservation?.idNewItems,
                    },
                    config,
                );
                if (data.status !== 400) {
                    setLoadingRefresh(false);
                    setAcessCode(data.data.data);
                }
            } catch (error: any) {

            }
        }
        listVoucher();
    }, [currentReservation, currentReservation?.idNewItems, isLoad]);

    /* useEffect(() => {
        const removeElem: any = document.querySelectorAll(
            '.bg-voucher-download tbody tr td input',
        );
        const removeElemMobile: any = document.querySelectorAll(
            '.bg-voucher-download #card-table tbody',
        );
        const trElem: any = document.querySelectorAll(
            '.bg-voucher-download tbody tr',
        );
        const trElemMobile: any = document.querySelectorAll(
            '.bg-voucher-download #card-table',
        );
        for (let i = 0; i < acessCode?.length; i++) {
            if (acessCode[i].texto.includes('Usado') || acessCode[i].texto.includes('Cancelado')) {
                trElem[i].style =
                    'pointer-events: none; border-color: #cecece !important;border-right: 1px solid #cecece !important; border-top: 1px solid #cecece !important; border-bottom: 1px solid #cecece !important; border-left: 2px solid #707070 !important; background-color: #cecece4f !important; transition: .3s;';
                trElemMobile[i].style =
                    'pointer-events: none; border-color: #cecece !important;border-right: 1px solid #cecece !important; border-top: 1px solid #cecece !important; border-bottom: 1px solid #cecece !important; border-left: 2px solid #707070 !important; background-color: #cecece4f !important; transition: .3s;';
                removeElem[i]?.remove();
                removeElemMobile[i]?.remove();
            }
        }
    }, [acessCode]); */

    function convertDate(cell: any) {
        return <>{cell?.split(' ')[0]}</>;
    }

    function convertToMobile(cell: any, row: any) {
        console.log("ROW", row)
        return (
            <>
                <div className='d-flex flex-column'>
                    <span className='mb-1'><strong>Data:</strong> {row.date?.split(' ')[0]}</span>
                    <span className='mb-1'><strong>Ticket:</strong> {row.qr}</span>
                    <span className='mb-1'><strong>Situação:</strong> {row.texto}</span>
                </div>
            </>
        )
    }


    const columns = [
        { dataField: 'date', text: 'Data', formatter: convertDate },
        { dataField: 'qr', text: 'Ticket' },
        { dataField: 'texto', text: 'Situação' },
        /* { dataField: 'startTimeLocal', text: 'Data Futura', formatter: inputDate }, */
    ];

    const columnsMobile = [
        { dataField: 'date', text: 'Data', formatter: convertToMobile },
    ];

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        );
    }

    const loadingColumns = [
        {
            dataField: 'contasd',
            text: 'Carregando Tickets',
            formatter: loadingFunc,
        },
    ];

    const loadingProducts = [
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
    ];
    /* [END] loading table */

    const rowClasses = (row: any) => {
        return '';
    };


    useEffect(() => {
        var aux: any = [];
        itemToBeListed.forEach((elem: any) => {
            aux.push(elem.qr);
        });
        setSelected(aux);
    }, [itemToBeListed])

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: (row: any, isSelect: any) => {
            if (isSelect === true) {
                var add: any;
                add = [...itemToBeListed, row];
                setItemToBeListed(add);
            } else {
                var exclude: any = [];
                for (var i: any = 0; i < itemToBeListed.length; i++) {
                    if (itemToBeListed[i].qr !== row.qr) {
                        exclude.push(itemToBeListed[i]);
                    }
                }

                setItemToBeListed(exclude);
            }
        },
        onSelectAll: (isSelect: any, rows: any) => {
            if (isSelect === true) {
                setItemToBeListed(rows);
            } else {
                setItemToBeListed([]);
            }
        },
    };

    const UpdateAcessCodes = async () => {
        if (date === null || date === '') {
            setModalLog(1);
            setModalMessage("Selecione uma data para o reagendamento!")
            setModalShow(true);

            return;
        }

        setLoading(true);
        setModalLog(null);
        setModalMessage("Carregando...")
        setModalShow(true);

        let qtdAdults: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Adult') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdChild: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Child') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdInfant: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Infant') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdElders: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Student') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdStudent: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Elders') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)


        let newDate: string = date?.split('/').reverse().join('-');
console.log('newdate', newDate)
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        try {
            if (itemToBeListed.length > 0) {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/Checkout/EditEnviaCheckoutSite`,
                    {
                        "firstName": item.nome,
                        "lastName": item.sobrenome,
                        "phone": item.telefone,
                        "email": item.email,
                        "idTarif": item.idTarifario,
                        "internalNotes": item.internalNotesItem,
                        "IdNewItem": item.idItem,
                        "date": `${newDate}T${dateHours}`,
                        "hora": dateHours,
                        "idBooking": item.idBookings,
                        "BookingLoc": item.bookingLoc,
                        "Foreign": 1,
                        "Language": 1,
                        "newProduct": item.idCanal === 0 ? 1 : 0,
                        "adults": qtdAdults,
                        "childs": qtdChild,
                        "infants": qtdInfant,
                        "elders": qtdElders,
                        "students": qtdStudent,
                        "accessCodeListToEdit": itemToBeListed?.map((elem: any) => { return (elem.qr) })
                    },
                    config,
                );
                if (data.status !== 400) {

                    if (data.data.log === 0 || data.data.log === '0') {
                        setPartialReschedulingModal(false);
                        setModalLog(data.data.log);
                        setModalMessage(data.data.texto);
                        setLoading(false);
                    } else if (data.data.log === 1 || data.data.log === '1') {
                        setModalLog(data.data.log);
                        setLoading(false);
                        setModalMessage(data.data.texto);
                    }
                }
            } else {
                setModalLog(1);
                setLoading(false);
                setModalMessage('Por favor, selecione um ticket para ser alterado');
            }
        } catch (error: any) {
            setLoading(false);
            setModalLog(1);
            setModalMessage("Erro ao processar operação!");
        }
    };


    const getAccessCode = async (idItem: any) => {
        setLoadingRefresh(true);

        try {
            const { data } = await api.get(
                `/api/NewReservations/UpdateStatusSkidataReservationsByIdAsync/${idItem}`,
            );

            if (data.status !== 400) {
                /* setLoadingRefresh(false); */
                setIsLoad(!isLoad);
            }
        } catch (error: any) { }
    };

    const logReprint = async (vouchers: any) => {
        // Log de Reimpressão
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        try {
            const { data } = await api.post(`/api/Reservations/SavePrintLog`, {
                idItem: currentReservation.idNewItems,
                qr: vouchers,
                Uid: useId
            });
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    //setManagement('success');
                }, 5000);
            } else {
                //setManagement("error");
            }
        } catch (e) {
            //setManagement("error");
        }
    };

    useEffect(() => {

    }, [])

    if (acessCode !== null && loadingRefresh === false) {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            Reagendamento Parcial
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialReschedulingModal(false);
                        }}
                    />
                </Modal.Header>
                <div className="bg-rescheduling">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>

                    <div className='mt-3 d-flex justify-content-between align-items-center w-100 row'>
                        <small className='mb-2 col-md-9' style={{ fontWeight: 600 }}>*Os tickets selecionados serão reagendados para outra data</small>
                        <div className="col-md-3">
                            {option !== null && item !== null ?
                                <>
                                    {item?.productType === 4
                                        ?
                                        <DoubleCalendarTicket
                                            modalityID={option.productCode}
                                            product={option}
                                            productCode={item.productCode}
                                            changePriceDateTour={(e: any) => { setDate(e[0].data)}}
                                            dateTour={[{}]}
                                            index={0}
                                            setRanges={() => { }}
                                            isIntegration={isIntegration}
                                            setIsIntegration={setIsIntegration}
                                            setApiError={() => { }}
                                        ></DoubleCalendarTicket>
                                        :
                                        <>
                                            <DoubleCalendar
                                                modalityID={option.productCode}
                                                product={option}
                                                productCode={item.productCode}
                                                changePriceDateTour={(e: any) => { setDate(e[0].data)}}
                                                dateTour={[{}]}
                                                index={0}
                                                setRanges={() => { }}
                                                setIsIntegration={setIsIntegration}
                                                setApiError={() => { }}
                                            ></DoubleCalendar>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <div className="load small"></div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="table-default mt-2">
                        <FontAwesomeIcon
                            icon={['fad', 'redo-alt']}
                            className="reload-btn text-primary mr-2 d-md-block d-none"
                            onClick={() => {
                                getAccessCode(currentReservation?.idNewItems);
                            }}
                            size="1x"
                        />
                        <div className='d-none d-md-block'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columns}
                                rowClasses={rowClasses}
                            />
                        </div>
                        <div className='d-block d-md-none'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columnsMobile}
                                rowClasses={rowClasses}
                            />
                        </div>
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-end px-0 pb-0">
                    <button className="btn btn-outline-primary" onClick={() => { setPartialReschedulingModal(false) }}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={UpdateAcessCodes}>
                        {loading === true ? <div className="load small white"></div> : 'Confirmar'}
                    </button>
                </Modal.Footer>
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            Reagendamento Parcial
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-info"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialReschedulingModal(false);
                        }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>
                    <div className="table-default loading">
                        <BootstrapTable
                            bootstrap4
                            keyField="qr"
                            selectRow={selectRow}
                            data={loadingProducts}
                            columns={loadingColumns}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default PartialRescheduling;