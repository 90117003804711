/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, Key } from 'react';
import api from "../../../services/api";
import { Modal } from 'react-bootstrap';
import DetailsReservation from '../DetailsReservation/DetailsReservation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "@restart/ui/esm/Button";

import { useTranslation } from "react-i18next";

import "./ListReservations.scss";
import ModalConfirm from '../../Modal/ModalConfirm/ModalConfirm';
import PartialEdit from '../components/PartialEdit/PartialEdit';
import PartialRescheduling from '../components/PartialRescheduling/PartialRescheduling';
import ModalShow from '../../Modal/ModalShow/ModalShow';

function ListReservations() {
    const [t] = useTranslation();

    const [reservations, setReservations] = useState<any>(null);
    const [cancelamentoModal, setCancelamentoModal] = useState<any>(false);
    const [cancelTextResponse, setCancelTextResponse] = useState<any>('');
    const [detailsModal, setDetailsModal] = useState<any>(false);
    const [alertBool, setAlertBool] = useState<any>(false);
    const [alertMessage, setAlertMessage] = useState<any>("");
    const [currentReservation, setCurrentReservation] = useState<any>();

    const [modalContent, setModalContent] = useState<any>();

    const [confirm, setConfirm] = useState<any>("Tem certeza que deseja cancelar esse item?");
    const [log, setLog] = useState<any>();

    const [partialEditModal, setPartialEditModal] = useState<any>(false);
    const [partialReschedulingModal, setPartialReschedulingModal] = useState<any>(false);

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('Carregando...');

    const ReservationList = async () => {
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token).token.token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/api/NewReservations/GetMyReservationsAsync`, '{}', config);
            if (res.status !== 400) {
                setReservations(res.data.data);
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/login';
            }
        }
    };

    useEffect(() => {
        ReservationList();
    }, []);

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any, isHour: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        if (isHour === true) {
            return newDate.toLocaleString().split(' ')[1];
        } else {
            return newDate.toLocaleString().split(' ')[0];
        }
    }

    function convertDate(cell: any, isHour: any = false) {
        var date = convertUTCDateToLocalDate(new Date(cell), false);
        var hours = convertUTCDateToLocalDate(new Date(cell), true);

        if (isHour === true) {
            return (
                <>
                    {hours}
                </>
            );
        } else {
            return (
                <>
                    {date.split(",")[0]}
                </>
            );
        }
    }

    function cancelReservation(item: any) {
        // setCancelamentoModal(true);

        setModalContent('loading');
        const cancelamento = async () => {
            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token).token.token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/api/Reservations/TryCancelItemSite`, {
                    "idItem": item.idNewItems
                }, config);
                if (res.status !== 400) {
                    if (res.data.data.log === 0 || res.data.data.log === '0') {
                        setConfirm(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent('sucesso');
                    } else {
                        setConfirm(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent('error');
                    }
                    setCancelTextResponse(res.data.data.texto);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }
            }
        };

        if (confirm) {
            cancelamento();
        } else {
            // alert("Action canceled");
        }
    }

    function getVoucher(idItem: any) {
        // setCancelamentoModal(true);

        const getVou = async () => {
            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token).token.token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/api/checkout/PdfSecondWay/${idItem}`, {

                }, config);
                if (data.status !== 400) {
                    if (data.data.log === 0 || data.data.log === '0') {

                        const link = document.createElement('a');
                        link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
                        link.setAttribute(
                            'download',
                            data.data.data.voucherFile.fileName,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // // Clean up and remove the link
                        link?.parentNode?.removeChild(link);
                    } else if (data.data.log === 1 || data.data.log === '1') {
                        setAlertMessage(data.data.data);
                        setAlertBool(true);
                        /* alert(data.data.data.texto) */
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }
                // if(data.status === 401){
                //     window.location.href = window.location.origin + '/login';
                // }
            }
        };
        getVou();
    }

    async function EmailTotemSecond(idItem: any, idioma: any) {
        const lang = idioma === "PT" ? "BR" : idioma;

        try {
            const print = await api.post(`/api/Checkout/PdfTotemSecondWay?IdItem=${idItem}&language=${lang}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    responseType: 'blob'
                },

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'Recibo.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            //(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    const openPartialEdit = (idItem: any) => {
        setCurrentReservation(idItem);
        setPartialEditModal(true);
    };

    const openPartialRescheduling = (idItem: any) => {
        setCurrentReservation(idItem);
        setPartialReschedulingModal(true);
    };


    if (reservations !== null) {

        return (
            <>
                {reservations.length > 0
                    ? reservations.map((reservation: any, index: Key) => {
                        return (
                            <div key={index}>
                                <div className="card mb-5 rounded-0">
                                    <div

                                        className={reservation.status === 1 ? "card-header bg-primary text-white d-flex flex-row flex-wrap justify-content-between align-items-center" : "card-header bg-black-10 text-black d-flex flex-row flex-wrap justify-content-between align-items-center"}
                                    >
                                        <span>
                                            <span className="text-uppercase font-weight-bold p-1">{t("myReservations.codReservation")}{reservation.reservaC2}</span>
                                        </span>
                                        <span className={reservation.status === 1 ? "d-block d-md-inline pr-3 float-right text-white" : "d-block d-md-inline pr-3 float-right text-black"}>
                                            {reservation.status === 1 ? t("myReservations.confirm") : t("myReservations.cancel")} </span>
                                    </div>
                                    <div className="row p-3 pb-0">
                                        <div className="col-12 col-md-12">
                                            <div className="row">
                                                <div className="col-12 col-md-12">
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <h5 className="text-uppercase mb-3 text-primary">
                                                                {reservation.productName}
                                                            </h5>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="col-12 col-md-3 text-right w-100">
                                                                {reservation.status === 1 && reservation.source !== "TOTEM"
                                                                    ?
                                                                    <ModalConfirm
                                                                        confirm={confirm}
                                                                        log={log}
                                                                        reservation={reservation}
                                                                        cancelReservation={cancelReservation}
                                                                        modalContent={modalContent}
                                                                        setConfirm={setConfirm}
                                                                        setLog={setLog}
                                                                    />
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pb-2 row">
                                                        <div className="col-12 col-md-4 mt-2 mt-md-0 order-md-1">
                                                            <span className="d-block d-md-inline pr-3"
                                                            ><i className="far fa-calendar-check fa-fw text-primary"></i>
                                                                <strong className="text-dark">{t("myReservations.date")}</strong> {convertDate(reservation.startTimeLocal)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 d-none">
                                                    <label htmlFor="transfer-de"
                                                    ><div className="small py-2 mb-3 mt-2">
                                                            <h6>
                                                                <i className="fal fa-map-marked-alt fa-fw text-primary"></i>
                                                                <strong className="text-dark text-uppercase">{t("myReservations.info")}</strong>
                                                            </h6>
                                                            <hr className="mt-0 mb-1" />
                                                        </div>
                                                        <select
                                                            className="js-single js-states form-control border-primary mb-4 d-none"
                                                            id="transfer-de"
                                                        >
                                                            <option>Selecione seu local de embarque</option>
                                                            <option value="AL">Othon Palace Hotel</option>
                                                            <option value="WY">Copacabana Palace</option>
                                                        </select>
                                                    </label>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-sm-3 mt-2 mt-md-0">
                                                        {reservation.status === 1 ?
                                                            <a
                                                                href="#Voucher01"
                                                                title="tour"
                                                                onClick={() => { getVoucher(reservation.idNewItems) }}
                                                                data-modalidaden="Um Dia no Rio com Almoço Executivo"
                                                                data-toggle="modal"
                                                                className="btn btn-secondary btn-block w-100"
                                                            ><FontAwesomeIcon
                                                                    icon={["fal", "ticket-alt"]}
                                                                    size="1x"
                                                                    className="ml-2"
                                                                /> Voucher
                                                            </a>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-sm-3 mt-2 mt-md-0">
                                                        {reservation.status === 1 && reservation.idMetodoPagamento !== 88
                                                            ?
                                                            <a
                                                                href="#Recibo01"
                                                                title="tour"
                                                                onClick={() => { EmailTotemSecond(reservation.idNewItems, reservation.idioma) }}
                                                                data-modalidaden="Um Dia no Rio com Almoço Executivo"
                                                                data-toggle="modal"
                                                                className="btn btn-secondary btn-block w-100"
                                                            ><FontAwesomeIcon
                                                                    icon={["fal", "download"]}
                                                                    size="1x"
                                                                    className="ml-2"
                                                                /> Recibo
                                                            </a>
                                                            :
                                                            <></>
                                                        }
                                                    </div>

                                                    {/* <div className="col-12 col-sm-3 mt-2 mt-md-0">
                                                        {reservation.status === 1 &&
                                                            <a
                                                                href="#Recibo01"
                                                                title="tour"
                                                                onClick={() => { openPartialEdit(reservation.idNewItems) }}
                                                                data-modalidaden="Um Dia no Rio com Almoço Executivo"
                                                                data-toggle="modal"
                                                                className="btn btn-secondary btn-block w-100"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "ban"]}
                                                                    size="1x"
                                                                    className="ml-2"
                                                                /> Cancelamento Parcial
                                                            </a>
                                                        }
                                                    </div> */}
                                                    {/* <div className="col-12 col-sm-3 mt-2 mt-md-0">
                                                        {reservation.status === 1 &&
                                                            <a
                                                                href="#Recibo01"
                                                                title="tour"
                                                                onClick={() => { openPartialRescheduling(reservation.idNewItems) }}
                                                                data-modalidaden="Um Dia no Rio com Almoço Executivo"
                                                                data-toggle="modal"
                                                                className="btn btn-secondary btn-block w-100"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "edit"]}
                                                                    size="1x"
                                                                    className="ml-2"
                                                                /> Reagendamento Parcial
                                                            </a>
                                                        }
                                                    </div> */}

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12 pt-4">
                                            <div className="row preco align-items-center">
                                                <div className="col">
                                                    <small className="d-block"
                                                    ><i className="fal fa-users text-primary"></i>
                                                        <strong>{t("myReservations.totalPax")}{reservation.quantidadePax}</strong></small>
                                                </div>
                                                {/* <div className="col-5 col-md-4 text-right">
                                                    <span className="h6 text-muted">R$</span><span className="h5 text-primary font-weight-bold"></span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 bg-black-10 px-3 py-3 mt-2">
                                        <div className="row preco align-items-center">
                                            <div className="col">
                                                <small className="d-block pt-2"
                                                ><i className="fal fa-money-bill-wave text-primary"></i>
                                                    <strong>{t("myReservations.payWith")}</strong> {reservation.formaPagamento} </small>
                                            </div>
                                            <div className="col-5 col-md-4 text-right">
                                                <span className="h6 text-muted">R$</span><span className="h5 text-primary font-weight-bold"> {(reservation.amount).toFixed(2).split(".").join(",")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    })
                    : ''}

                <Modal
                    className="modal-custom reservation-cancel"
                    show={cancelamentoModal !== false}
                    onHide={() => setCancelamentoModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body>
                            {cancelTextResponse}
                        </Modal.Body>
                    </>
                </Modal>

                <Modal
                    className="modal-custom voucher-download"
                    show={partialEditModal !== false}
                    onHide={() => { ReservationList(); setDetailsModal(false); }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <PartialEdit
                            setPartialEditModal={setPartialEditModal}
                            currentReservation={{ idNewItems: currentReservation }}
                            setModalShow={setModalShow}
                            setModalLog={setModalLog}
                            setModalMessage={setModalMessage}
                        />
                    </>
                </Modal>

                <Modal
                    className="modal-custom voucher-download"
                    show={partialReschedulingModal !== false}
                    onHide={() => { ReservationList(); setDetailsModal(false) }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <PartialRescheduling
                            setPartialReschedulingModal={setPartialReschedulingModal}
                            currentReservation={{ idNewItems: currentReservation }}
                            setModalShow={setModalShow}
                            setModalLog={setModalLog}
                            setModalMessage={setModalMessage}
                        />
                    </>
                </Modal>


                <Modal
                    className="modal-custom reservation-details"
                    show={detailsModal !== false}
                    onHide={() => setDetailsModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body>
                            <DetailsReservation id={currentReservation} setDetailsModal={setDetailsModal} />
                        </Modal.Body>
                    </>
                </Modal>

                <Modal
                    className="modal-confirm"
                    show={alertBool}
                    onHide={() => {
                        setAlertBool(false)
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className='modal-body text-center'>
                        <div>
                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                style={{ fontSize: "7.5em", color: "#FF6600" }}
                            />

                        </div>
                        <div className="px-md-5" style={{ padding: "0 3rem" }}>
                            {alertMessage}
                        </div>
                        <div>
                            <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertBool(false)}>Fechar</Button>
                        </div>
                    </div>
                </Modal>

                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                    reloadTable={()=>{ReservationList(); setPartialEditModal(false); setPartialReschedulingModal(false);}}
                />
            </>
        );
    } else {
        return (
            <>
                {
                    [1, 2, 3].map((reservation: any, index: Key) => {
                        return (
                            <div>
                                <div className="card mb-5 rounded-0">
                                    <div

                                        className="card-header bg-primary text-white d-flex flex-row flex-wrap justify-content-between align-items-center"
                                    >
                                        <span className='animated-background' style={{ height: "24px", width: "360px" }}></span>
                                        <span className="animated-background" style={{ height: "24px", width: "180px" }}></span>
                                    </div>
                                    <div className="row p-3 pb-0">

                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className="col-12 col-md-12">
                                                    <p className="animated-background" style={{ height: "24px", width: "360px" }}></p>
                                                </div>
                                                <div>
                                                    <p className="col-12 col-sm-6 animated-background" style={{ height: "24px", width: "180px" }}></p>
                                                </div>
                                                <div className="col-12 col-sm-6 mt-2 mt-md-0">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 text-right d-flex justify-content-end">
                                            <p className="animated-background" style={{ height: "24px", width: "240px", marginRight: "0" }}></p>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <p className="animated-background" style={{ height: "48px", width: "360px", marginRight: "30px", borderRadius: "8px" }}></p>
                                            </div>
                                            <div>
                                                <p className="animated-background" style={{ height: "48px", width: "360px", marginRight: "30px", borderRadius: "8px" }}></p>
                                            </div>
                                        </div>
                                        <div className="col-12 pt-4">
                                            <div className="row preco align-items-center">
                                                <div className="col">
                                                    <p className="animated-background" style={{ height: "24px", width: "180px", marginRight: "0" }}></p>
                                                </div>
                                                <div className="col-5 col-md-4 text-right animated-background" style={{ height: "24px", width: "90px", marginRight: "0" }}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 col-md-4 text-right">
                                            <span className="h6 text-muted">{t("myReservations.total")} R$</span><span className="h5 text-primary font-weight-bold"> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }

            </>
        );
    }
}

export default ListReservations;